import api from './api';

export const updateArray = (array, value, index) => [
  ...array.slice(0, index),
  { ...array[index], ...value },
  ...array.slice(index + 1),
];

export const deleteFromArray = (array, index) => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
];

export const checkTnvedsEmpty = (tnveds, deliverysInfo) => {
  const requiredFields = ['code', 'weight', 'volume', 'volumeWeight'];
  if ([2, 3].includes(deliverysInfo.contractType)) {
    requiredFields.push('riskKg');
  }

  if (deliverysInfo.contractType !== 3) {
    requiredFields.push('price');
  }

  if (deliverysInfo.deliveryType === 4) {
    requiredFields.push('countSeats');
    requiredFields.push('countPieceInSeats');
  }

  const tnvedsEmpty = tnveds.some(
    (tnved, idx) =>
      (tnved.code === '' && idx === 0) ||
      requiredFields.some((field) => tnved[field] === '') ||
      Object.keys(tnved.detail).length === 0
  );

  let ftlOk = false;
  if (deliverysInfo.deliveryType === 4) {
    const ftlRequired = [
      'borderPrice',
      'rate13',
      'rate17',
      'inputRoutePrice',
      'numberCollectionPoints',
    ];
    ftlOk = ftlRequired.some((el) => !deliverysInfo[el]);
  }

  return tnvedsEmpty || ftlOk;
};

export const convertTnvedsToSend = (tnveds) =>
  tnveds.map((tnved) => {
    const additionalUnitValues = [];
    if (tnved.detail.additionalUnits && tnved.detail.additionalUnits[0]?.name) {
      additionalUnitValues.push(+tnved.gedI1 || 0);
    }
    if (tnved.detail.additionalUnits && tnved.detail.additionalUnits[1]?.name) {
      additionalUnitValues.push(+tnved.gedI2 || 0);
    }
    if (
      tnved.detail.typesExcise &&
      tnved.detail.typesExcise[tnved.idAkciz]?.additionalUnitsItem
    ) {
      additionalUnitValues.push(+tnved.gedIAkciz || 0);
    }
    if (
      tnved.detail.typesImportDuty &&
      tnved.detail.typesImportDuty[tnved.idPoshlina]?.additionalUnitsItem
    ) {
      additionalUnitValues.push(+tnved.gedIPoshlina || 0);
    }
    if (
      tnved.detail.typesVat &&
      tnved.detail.typesVat[tnved.idVat]?.additionalUnitsItem
    ) {
      additionalUnitValues.push(+tnved.gedIVat || 0);
    }

    return {
      code: +tnved.code,
      price: +tnved.price,
      riskPrice: +tnved.riskKg * +tnved.weight,
      weight: +tnved.weight,
      volume: +tnved.volume,
      volumeWeight: +tnved.volumeWeight,
      count: +tnved.count,
      countSeats: +tnved.countSeats,
      countPieceInSeats: +tnved.countPieceInSeats,
      isPhotoReport: !!tnved.countPieceInSeats,
      isOversizePlaces: !!tnved.isOversizePlaces,
      packageType: +tnved.packageType,
      description: tnved.description,
      idVat: tnved.idVat,
      idAkciz: tnved.idAkciz,
      idPoshlina: tnved.idPoshlina,
      idAntiDumpingDuty: tnved.idAntiDumping,
      idCountervailingDuty: tnved.idCountervailingDuty,
      additionalUnitValues,
    };
  });

export const getResultRecurs = (id, signal, callback) => {
  function getResult() {
    setTimeout(() => {
      api.getResult(id, signal).then((res) => {
        if (res.status === 'Succeeded') {
          callback(res.result);
        } else if (res.status !== 'Error' && res.status !== 'Failed') {
          getResult();
        } else callback('Error');
      });
    }, 500);
  }

  getResult();
};
