import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './SelectsBlock.module.scss';
import api from '../../../utils/api';

import {
  selectStyles,
  deliveryTypeOprions,
  contractTypeOprions,
} from '../../../utils/variables';

const dropdownNone = {
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none;',
  }),
};

const error = (isError) => ({
  control: (provided) => ({
    ...provided,
    border: isError ? '1px solid #e44b4b' : 'none',
    boxShadow: 'none',
    ':hover': {
      border: isError ? '1px solid #e44b4b' : 'none',
      boxShadow: 'none',
    },
    height: 40,
  }),
});

const loadCityes = (val, callback) => {
  if (val.length > 0) {
    api.getCitiesByWord(val).then((res) => {
      callback(
        res.cities &&
          res.cities.map((item) => ({ label: item.title, value: item.id }))
      );
    });
  } else {
    callback([]);
  }
};

const loadExternal = (val, callback) => {
  if (val.length > 0) {
    api.getExternalRoute(val).then((res) => {
      callback(
        res &&
          res.map((item, idx) => ({
            ...item,
            label: item,
            value: idx,
          }))
      );
    });
  } else {
    callback([]);
  }
};

const loadInput = (val, callback) => {
  if (val.length > 0) {
    api.getInputRoute(val).then((res) => {
      callback(
        res &&
          res.map((item, idx) => ({
            ...item,
            label: item,
            value: idx,
          }))
      );
    });
  } else {
    callback([]);
  }
};

function SelectsBlock({ deliverysInfo, setDeliveryInfo }) {
  const isRisk = [2, 3].includes(deliverysInfo.contractType);
  const isFTL = deliverysInfo.deliveryType === 4;

  const [borderOptions, setBorderOptions] = useState([]);
  useEffect(() => {
    if (deliverysInfo.deliveryType === 4) {
      if (deliverysInfo.startCity && deliverysInfo.endCity) {
        api
          .getBorderRoute(deliverysInfo.startCity, deliverysInfo.endCity)
          .then((res) => {
            if (res) {
              setBorderOptions(
                res.map((item) => ({
                  ...item,
                  label: `${item.externalPoint} ${item.internalPoint}`,
                  value: item.id,
                  inputRoutePrice: item.inputRouteInfo.rate,
                  borderPrice: item.rate,
                }))
              );
            }
          });
      } else {
        setBorderOptions([]);
      }
    }
  }, [deliverysInfo.startCity, deliverysInfo.endCity]);

  const standartCityBlock = (
    <>
      <div className={styles['select-wrapper']}>
        <span className={styles.title}>Город отгрузки</span>
        <AsyncSelect
          loadOptions={loadCityes}
          onChange={(val) =>
            setDeliveryInfo({
              ...deliverysInfo,
              startCity: (val && val.label) || '',
            })
          }
          styles={{ ...selectStyles, ...dropdownNone }}
          placeholder="Введите город отгрузки..."
          noOptionsMessage={() => 'Не найдено подходящих названий...'}
          isClearable
        />
      </div>
      <div className={styles['select-wrapper']}>
        <span className={styles.title}>Город назначения</span>
        <AsyncSelect
          loadOptions={loadCityes}
          onChange={(val) =>
            setDeliveryInfo({
              ...deliverysInfo,
              endCity: (val && val.label) || '',
            })
          }
          styles={{ ...selectStyles, ...dropdownNone }}
          placeholder="Введите город назначения..."
          noOptionsMessage={() => 'Не найдено подходящих названий...'}
          isClearable
        />
      </div>
    </>
  );

  const FTLCityBlock = (
    <>
      <div className={styles['select-wrapper']}>
        <span className={styles.title}>Провинция и Город отгрузки</span>
        <AsyncSelect
          cacheOptions
          loadOptions={loadExternal}
          onChange={(val) =>
            setDeliveryInfo({
              ...deliverysInfo,
              startCity: (val && val.label) || '',
            })
          }
          styles={{
            ...selectStyles,
            ...dropdownNone,
            ...error(!deliverysInfo.startCity),
          }}
          placeholder="Введите город в Китае"
          noOptionsMessage={() => 'Не найдено подходящих названий...'}
        />
      </div>

      <div className={styles['select-wrapper']}>
        <span className={styles.title}>Количество пунктов забора груза</span>
        <input
          type="text"
          className={cn(styles.input, {
            [styles.error]: !+deliverysInfo.numberCollectionPoints,
          })}
          placeholder="Введите количество пунктов забора груза"
          value={deliverysInfo.numberCollectionPoints || ''}
          onChange={(evt) => {
            const { value } = evt.target;
            if (/^[0-9]*$/.test(value)) {
              setDeliveryInfo({
                ...deliverysInfo,
                numberCollectionPoints: evt.target.value,
              });
            }
          }}
        />
      </div>

      <div className={styles['select-wrapper']}>
        <span className={styles.title}>Город назначения</span>
        <AsyncSelect
          loadOptions={loadInput}
          onChange={(val) =>
            setDeliveryInfo({
              ...deliverysInfo,
              endCity: (val && val.label) || '',
            })
          }
          styles={{
            ...selectStyles,
            ...dropdownNone,
            ...error(!deliverysInfo.endCity),
          }}
          placeholder="Введите город назначения"
          noOptionsMessage={() => 'Не найдено подходящих названий...'}
        />
      </div>

      <div className={styles['select-wrapper']}>
        <span className={styles.title}>Пограничный переход</span>
        <Select
          options={borderOptions}
          onChange={(val) =>
            setDeliveryInfo({
              ...deliverysInfo,
              border: (val && val.label) || '',
              borderPrice: val.rate,
              inputRoutePrice: val.inputRouteInfo.rate,
              rate13: val.externalRouteInfo.rate13,
              rate17: val.externalRouteInfo.rate17,
            })
          }
          styles={{
            ...selectStyles,
            ...dropdownNone,
            ...error(!deliverysInfo.borderPrice),
          }}
          defaultOptions
          placeholder="Введите пограничный переход"
          noOptionsMessage={() => 'Не найдено подходящих названий...'}
          isDisabled={!deliverysInfo.endCity || !deliverysInfo.startCity}
        />
      </div>
    </>
  );

  return (
    <>
      <div className="col-12 col-lg-6">
        <div className={styles['calc-block']}>
          <div className={styles['select-wrapper']}>
            <span className={styles.title}>Условия поставки</span>
            <Select
              styles={selectStyles}
              placeholder="Выберите тип контракта"
              options={contractTypeOprions}
              value={contractTypeOprions.find(
                (item) => item.value === deliverysInfo.contractType
              )}
              onChange={(val) => {
                if ([2, 3].includes(val.value) && isFTL) {
                  setDeliveryInfo({
                    ...deliverysInfo,
                    deliveryType: 0,
                    contractType: val.value,
                    startCity: '',
                    endCity: '',
                    borderPrice: null,
                    numberCollectionPoints: null,
                    border: '',
                    rate13: null,
                    rate17: null,
                    inputRoutePrice: null,
                  });
                } else {
                  setDeliveryInfo({
                    ...deliverysInfo,
                    contractType: val.value,
                  });
                }
              }}
              isSearchable={false}
            />
          </div>

          <div className={styles['select-wrapper']}>
            <span className={styles.title}>Тип перевозки</span>
            <Select
              styles={selectStyles}
              placeholder="Выберите тип контракта"
              options={deliveryTypeOprions.slice(0, isRisk ? 4 : 5)}
              value={deliveryTypeOprions.find(
                (item) => item.value === deliverysInfo.deliveryType
              )}
              onChange={(val) => {
                if (val.value === 4 || deliverysInfo.deliveryType === 4) {
                  setDeliveryInfo({
                    ...deliverysInfo,
                    deliveryType: val.value,
                    startCity: '',
                    endCity: '',
                    borderPrice: null,
                    numberCollectionPoints: null,
                    border: '',
                    rate13: null,
                    rate17: null,
                    inputRoutePrice: null,
                  });
                } else {
                  setDeliveryInfo({
                    ...deliverysInfo,
                    deliveryType: val.value,
                  });
                }
              }}
              isSearchable={false}
            />
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-6">
        <div className={styles['calc-block']}>
          <div className="w-100">
            {!isFTL && standartCityBlock}
            {isFTL && FTLCityBlock}
          </div>
        </div>
      </div>
    </>
  );
}

SelectsBlock.propTypes = {
  deliverysInfo: PropTypes.shape({
    contractType: PropTypes.number,
    deliveryType: PropTypes.number,
    numberCollectionPoints: PropTypes.string,
    borderPrice: PropTypes.number,
    externalRouteTruck13Price: PropTypes.number,
    externalRouteTruck17Price: PropTypes.number,
    inputRoutePrice: PropTypes.number,
    startCity: PropTypes.string,
    endCity: PropTypes.string,
  }).isRequired,
  setDeliveryInfo: PropTypes.func.isRequired,
};

export default SelectsBlock;
