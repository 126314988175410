import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import 'reactjs-popup/dist/index.css';

import styles from './PdfBlock.module.scss';

import { getResultRecurs } from '../../../utils/utils';
import api from '../../../utils/api';
import Loader from '../../Loader/Loader';

let controller = null;
let controllerEmail = null;
let controllerBitrix = null;

const cookie = document.cookie.match(
  new RegExp(
    `(?:^|; )${'BITRIX_CRM_IS_AUTH'.replace(
      /([.$?*|{}()[\]\\/+^])/g,
      '\\$1'
    )}=([^;]*)`
  )
);
const isLogin = cookie ? cookie[1] === 'Y' : false;

function PdfBlock({
  bill,
  billId,
  loadingBill,
  deliverysInfo,
  exciseDuty,
  additionalUnit,
}) {
  const [pdfLoading, setPdfLoading] = useState('Генерация PDF...');
  const [emailLoading, setEmailLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [bitrixLoading, setBitrixLoading] = useState(false);
  const [dealName, setDealName] = useState('');

  useEffect(() => {
    if (loadingBill === 'Done') {
      setPdfLoading('Генерация PDF...');
      api
        .getPDF(
          billId,
          deliverysInfo.startCity,
          deliverysInfo.endCity,
          deliverysInfo.border
        )
        .then((res) => {
          if (controller) controller.abort();
          controller = new AbortController();
          getResultRecurs(res, controller.signal, (val) => {
            if (val !== 'Error' && val !== 'Ошибка получения пдф.') {
              const binary = atob(val.replace(/\s/g, ''));
              const buffer = new ArrayBuffer(binary.length);
              const view = new Uint8Array(buffer);
              for (let i = 0; i < binary.length; i += 1) {
                view[i] = binary.charCodeAt(i);
              }

              const blob = new Blob([view], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob);

              const link = document.querySelector('#pdf');
              if (link) {
                link.href = url;
                link.download = 'offer.pdf';
                setPdfLoading('Скачать PDF');
              }
            } else if (val !== 'Cancel') {
              setPdfLoading('Не удалось сгенерировать PDF');
            }
          });
        });
    }
  }, [bill, deliverysInfo.deliveryType]);

  const sendEmael = (evt) => {
    evt.preventDefault();
    setEmailLoading(true);
    api
      .sendEmail(
        billId,
        email,
        deliverysInfo.startCity,
        deliverysInfo.endCity,
        deliverysInfo.border
      )
      .then((res) => {
        if (res !== 'Error') {
          if (controllerEmail) controllerEmail.abort();
          controllerEmail = new AbortController();
          getResultRecurs(res, controllerEmail.signal, (val) => {
            if (val !== 'Error') {
              alert('Расчёт успешно отправлен на почту!');
              setEmailLoading(false);
              setEmail('');
            } else {
              alert('Произошла ошибка! Не удалось отправить отчёт на почту!');
              setEmailLoading(false);
            }
          });
        } else {
          alert('Произошла ошибка! Не удалось отправить отчёт на почту!');
          setEmailLoading(false);
        }
      });
  };

  const sendBitrux = (evt) => {
    evt.preventDefault();
    setBitrixLoading(true);
    api
      .sendToBitrix(
        billId,
        deliverysInfo.startCity,
        deliverysInfo.endCity,
        deliverysInfo.border,
        dealName,
        exciseDuty,
        additionalUnit
      )
      .then((res) => {
        if (res !== 'Error') {
          if (controllerBitrix) controllerBitrix.abort();
          controllerBitrix = new AbortController();
          getResultRecurs(res, controllerBitrix.signal, (val) => {
            if (val !== 'Error') {
              alert('Расчёт успешно отправлен в Битрикс!');
              setBitrixLoading(false);
              setEmail('');
            } else {
              alert('Произошла ошибка! Не удалось отправить отчёт в Битрикс!');
              setBitrixLoading(false);
            }
          });
        } else {
          alert('Произошла ошибка! Не удалось отправить отчёт в Битрикс!');
          setBitrixLoading(false);
        }
      });
  };

  return (
    <div>
      <form
        className={cn(styles['input-block'], styles.row)}
        onSubmit={sendEmael}
      >
        <div className={cn(styles['email-title'], 'd-block d-md-none')}>
          Отправить расчёт на почту
        </div>
        <div>
          <div className={cn(styles['email-title'], 'd-none d-md-block')}>
            Отправить расчёт на почту
          </div>
          <input
            type="email"
            placeholder="Введите e-mail"
            value={email}
            onChange={(evt) => setEmail(evt.target.value)}
            required
          />
        </div>

        <button type="submit" disabled={emailLoading}>
          {emailLoading ? <Loader show /> : 'Отправить'}
        </button>

        <div className={styles['pdf-block']}>
          <a
            href="$"
            target="blank"
            className={cn({
              [styles.disabled]: pdfLoading !== 'Скачать PDF',
            })}
            id="pdf"
          >
            {pdfLoading}
          </a>
        </div>
      </form>

      {isLogin && (
        <>
          <div className={styles['bitrix-title']}>
            Отправить расчёт в Битрикс
          </div>
          <form className={styles['input-block']} onSubmit={sendBitrux}>
            <input
              type="input"
              placeholder="Введите название сделки"
              value={dealName}
              onChange={(evt) => setDealName(evt.target.value)}
              required
              className="me-3"
            />
            <button type="submit" disabled={bitrixLoading}>
              {bitrixLoading ? <Loader show /> : 'Отправить'}
            </button>
          </form>
        </>
      )}
    </div>
  );
}

PdfBlock.propTypes = {
  bill: PropTypes.shape({ name: PropTypes.string, term: PropTypes.string })
    .isRequired,
  billId: PropTypes.string.isRequired,
  loadingBill: PropTypes.string.isRequired,
  deliverysInfo: PropTypes.shape({
    contractType: PropTypes.number,
    deliveryType: PropTypes.number,
    border: PropTypes.string,
    endCity: PropTypes.string,
    startCity: PropTypes.string,
  }).isRequired,
  exciseDuty: PropTypes.string.isRequired,
  additionalUnit: PropTypes.string.isRequired,
};

export default React.memo(PdfBlock);
