import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Modal from '../../../Modal/Modal';
import styles from './SelectOption.module.scss';

function SelectOption({ onSelect, options, currentOptionIdx, title }) {
  return (
    <div className={styles['info-block']}>
      <div className={styles.subtitle}>{title}</div>
      <div className={styles.value}>{options[currentOptionIdx].name}</div>
      <div>
        {options.length > 1 && (
          <Modal
            label="Выбрать"
            title={title}
            buttonType="stroke"
            content={(close) => {
              const handleSelect = (idx) => {
                onSelect(idx);
                close();
              };
              return (
                <div className={styles.wrapper}>
                  {options.map((vat, idx) => (
                    <div
                      key={idx}
                      className={cn(styles.row, {
                        [styles.active]: idx === currentOptionIdx,
                      })}
                      onClick={() => {
                        if (idx !== currentOptionIdx) handleSelect(idx);
                      }}
                    >
                      <div>{vat.name}</div>
                      <div>{vat.description}</div>
                    </div>
                  ))}
                </div>
              );
            }}
          />
        )}
      </div>
    </div>
  );
}

SelectOption.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  currentOptionIdx: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default SelectOption;
