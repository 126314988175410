import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import SelectsBlock from './SelectsBlock/SelectsBlock';
import TnvedBlock from './TnvedBlock/TnvedBlock';

import styles from './Calc.module.scss';
import api from '../../utils/api';
import {
  convertTnvedsToSend,
  updateArray,
  deleteFromArray,
  checkTnvedsEmpty,
  getResultRecurs,
} from '../../utils/utils';
import { tnvedInitial } from '../../utils/variables';
import BillBlock from './BillBlock/BillBlock';
import Loader from '../Loader/Loader';
import PdfBlock from './PdfBlock/PdfBlock';
import LogoBlock from './LogoBlock/LogoBlock';
import YesNoBlock from './TnvedBlock/YesNoBlock/YesNoBlock';

let controller = null;

function Calc() {
  const [deliverysInfo, setDeliveryInfo] = useState({
    contractType: 0,
    deliveryType: 0,
    startCity: '',
    endCity: '',
    border: '',
    numberCollectionPoints: '',
    autoRedemption: false,
  });

  const [tnveds, setTnveds] = useState([tnvedInitial]);
  const tnvedsCorrect = !checkTnvedsEmpty(tnveds, deliverysInfo);

  const [bill, setBill] = useState(null);
  const [billId, setBillId] = useState(null);
  const [loadingBill, setLoadingBill] = useState('New');

  const requestBill = () => {
    if (controller) controller.abort();
    if (tnvedsCorrect) {
      setLoadingBill('Loading');

      const payload = {
        contractType: +deliverysInfo.contractType,
        deliveryType: +deliverysInfo.deliveryType,
        deliveryInfo: convertTnvedsToSend(tnveds),
        autoRedemption: deliverysInfo.autoRedemption,
      };
      if (deliverysInfo.deliveryType === 4) {
        payload.truckInfo = {
          borderPrice: deliverysInfo.borderPrice,
          externalRouteTruck13Price: deliverysInfo.rate13,
          externalRouteTruck17Price: deliverysInfo.rate17,
          inputRoutePrice: deliverysInfo.inputRoutePrice,
          numberCollectionPoints: +deliverysInfo.numberCollectionPoints,
        };
      }

      let apiEndpoint = api.сalculateTnvedDelivery.bind(api);
      if ([2, 3].includes(deliverysInfo.contractType)) {
        apiEndpoint = api.сalculateRiskDelivery.bind(api);
      }

      apiEndpoint(payload)
        .then((resId) => {
          if (resId !== 'Error') {
            setBillId(resId);
            controller = new AbortController();
            getResultRecurs(resId, controller.signal, (res) => {
              if (res !== 'Error') {
                setLoadingBill('Done');
                setBill(res);
              } else {
                setLoadingBill('Error');
              }
            });
          } else {
            setLoadingBill('Error');
          }
        })
        .catch(() => {
          setLoadingBill('Error');
        });
    } else {
      setLoadingBill('New');
    }
  };

  useEffect(() => {
    setBill(null);
    setLoadingBill(null);
    setLoadingBill('New');
  }, [tnveds, deliverysInfo]);

  return (
    <div className="row">
      <LogoBlock />

      <SelectsBlock
        deliverysInfo={deliverysInfo}
        setDeliveryInfo={setDeliveryInfo}
      />

      {[0, 1].includes(deliverysInfo.deliveryType) &&
        deliverysInfo.contractType !== 1 && (
          <div className="col-12">
            <div className={styles.auction}>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className={styles.title}>
                    Подключить услугу АВТОВЫКУПА С площадок 1688, Alibaba
                    TaoBao?
                  </div>
                  <div className={styles.text}>
                    Комиссия 10% от стоимости инвойса{' '}
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className={styles.col}>
                    <YesNoBlock
                      value={deliverysInfo.autoRedemption}
                      handleClick={(value) =>
                        setDeliveryInfo({
                          ...deliverysInfo,
                          autoRedemption: value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      {tnveds.map((_, idx) => (
        <TnvedBlock
          key={idx}
          tnved={tnveds[idx]}
          setTnved={(value) => {
            setTnveds((prevValue) => updateArray(prevValue, value, idx));
          }}
          isCanDelete={tnveds.length > 1}
          handleDelete={() => {
            setTnveds((prev) => deleteFromArray(prev, idx));
          }}
          deliveryType={deliverysInfo.deliveryType}
          contractType={deliverysInfo.contractType}
        />
      ))}

      <div className="col-6 col-md-5 col-lg-4">
        <button
          type="button"
          className={styles.button}
          onClick={() => {
            setTnveds([...tnveds, tnvedInitial]);
          }}
        >
          Добавить товар
        </button>
      </div>
      <div className="col-6 col-md-7 col-lg-8">
        <button
          type="button"
          disabled={!tnvedsCorrect}
          className={cn(styles.button, styles.calc)}
          onClick={requestBill}
        >
          <Loader show={loadingBill === 'Loading'} />
          {loadingBill === 'Error' ? (
            <div className={styles['bill-error']}>
              Произошла ошибка, не удалось провести расчёт!
            </div>
          ) : (
            'Рассчитать доставку'
          )}
        </button>
      </div>

      {bill && deliverysInfo.deliveryType !== null && (
        <div className={cn('col-12', styles.bill)}>
          <div className={styles['calc-block']}>
            <BillBlock bill={bill} deliveryType={deliverysInfo.deliveryType}>
              <PdfBlock
                bill={bill}
                billId={billId}
                loadingBill={loadingBill}
                deliverysInfo={deliverysInfo}
                exciseDuty={tnveds
                  .map((el) => {
                    if (
                      !el.detail.typesExcise ||
                      el.detail.typesExcise.length === 0
                    )
                      return '';
                    return el.detail.typesExcise[el.idAkciz].name;
                  })
                  .join(',')}
                additionalUnit={tnveds
                  .map((el) => {
                    if (!el.detail.additionalUnits) return '';
                    return `${el.detail.additionalUnits.name}=${el.gedI1}`;
                  })
                  .join(',')}
              />
            </BillBlock>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(Calc);
