import plain from '../images/plain.svg';
import container from '../images/container.svg';
import containerSbor from '../images/containerSbor.svg';
import avto from '../images/avto.svg';
import ftl from '../images/ftl.svg';

export const selectStyles = {
  menu: (provided) => ({
    ...provided,
    top: '35px',
    borderRadiusTopRight: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadiusTopLeft: 0,
    borderRadiusTopRight: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    border: 'none',
    cursor: state.isSelected ? 'auto' : 'pointer',
    backgroundColor: state.isSelected ? '#EFF2FA' : '#fff',
    margin: '5px',
    width: 'calc(100% - 10px)',
    borderRadius: '5px',
    color: '#A4A4A4',
    ':hover': {
      backgroundColor: '#EFF2FA',
      color: '#A4A4A4',
    },
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    ':hover': {
      border: 'none',
      boxShadow: 'none',
    },
    height: 40,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none;',
  }),
};

export const tnvedInitial = {
  code: '',
  price: '',
  riskPrice: '',
  weight: '',
  volume: '',
  volumeWeight: '',
  count: '',
  countSeats: '',
  countPieceInSeats: '',
  isPhotoReport: false,
  isOversizePlaces: false,
  packageType: 0,
  description: '',
  detail: {},
  idVat: 0,
  idAkciz: 0,
  idPoshlina: 0,
  idAntiDumping: 0,
  idCountervailingDuty: 0,
  gedI1: '',
  gedI2: '',
};

export const packOptions = [
  { name: 'Картонные коробки', id: 0 },
  { name: 'Мешки полиэтиленовые', id: 1 },
  { name: 'Металлическая обрешетка', id: 2 },
  { name: 'Деревянная обрешетка', id: 3 },
  { name: 'Деревянный ящик', id: 4 },
  { name: 'Фанерный ящик', id: 5 },
];

export const deliveryTypeOprions = [
  { value: 0, label: 'Сборный груз. Ж/Д', icon: avto },
  { value: 1, label: 'Сборный груз. Авиа', icon: plain },
  { value: 2, label: 'Контейнерная', icon: container },
  { value: 3, label: 'Сборный Контейнер', icon: containerSbor },
  { value: 4, label: 'FTL', icon: ftl },
];

export const contractTypeOprions = [
  { value: 0, label: 'Контракт импортера' },
  { value: 1, label: 'Брокерский контракт' },
  { value: 2, label: 'В риск с оплатой' },
  { value: 3, label: 'В риск без оплаты' },
];
