import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import 'reactjs-popup/dist/index.css';

import Logo from '../Logo/Logo';

import term from '../../../images/term.svg';
import delivery from '../../../images/delivery.svg';

import { deliveryTypeOprions } from '../../../utils/variables';
import styles from './BillBlock.module.scss';
import CurrencyBlock from '../CurrencyBlock/CurrencyBlock';

// const image = { 0: train, 1: plain, 2: ship, 3: ship };

function BillBlock({ bill, deliveryType, children }) {
  const deleveryOption = deliveryTypeOprions.find(
    (option) => option.value === deliveryType
  );

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.images}>
          <div className={styles.logo}>
            <Logo />
            <div className={styles.title}>Подробный расчет</div>
          </div>

          <div className={styles['icon-block']}>
            <img src={deleveryOption.icon} alt="" />
            <span>{deleveryOption.label}</span>
          </div>
          <div className={styles['icon-block']}>
            <img src={delivery} alt="" />
            <span>Доставка {bill.term}</span>
          </div>
          <div className={cn(styles['icon-block'], 'd-none d-md-flex')}>
            <img src={term} alt="" />
            <span>
              Расчет стоимости
              <br />
              действителен 5 дней
            </span>
          </div>

          <div className={styles.currency}>
            <CurrencyBlock type="small" />
          </div>
        </div>

        <div className={styles.bill}>
          {bill.items.map((item) => (
            <div className={cn('row', styles.row)} key={item.name}>
              <div className="col-5">{item.name}</div>
              <div className={item.unit ? 'col-4' : 'col-7'}>{item.value}</div>
              {item.unit && <div className="col-3">{item.unit}</div>}
            </div>
          ))}
        </div>

        <div className={styles.email}>{children}</div>
      </div>
    </>
  );
}

BillBlock.propTypes = {
  bill: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        unit: PropTypes.string,
      })
    ),
    term: PropTypes.string,
  }).isRequired,
  deliveryType: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default React.memo(BillBlock);
