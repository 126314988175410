import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import styles from './SearchTNVED.module.scss';
import api from '../../../utils/api';

import Loader from '../../Loader/Loader';

function SearchTNVED({ onSelect }) {
  const [word, setWord] = useState('');
  const [tnveds, setTnveds] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInput = (value) => {
    setLoading(true);
    api
      .getTNVED(value)
      .then((res) => {
        if (res !== 'Error') {
          setTnveds(res.tnved);
        }
      })
      .catch(() => {
        setTnveds([]);
      })
      .finally(() => setLoading(false));
  };

  const handleInputB = useMemo(
    () => debounce((value) => handleInput(value), 800),
    []
  );

  return (
    <div className="w-100 h-100">
      <div className={styles.header}>
        <div>Поиск по наименованию</div>
        <div className={styles['input-wrapper']}>
          <input
            value={word}
            onChange={(evt) => {
              setWord(evt.target.value);
              if (evt.target.value.length >= 3) handleInputB(evt.target.value);
            }}
            placeholder="Наименование"
          />
        </div>
        <div>
          {word.length < 3
            ? 'Введите минимум 3 символа'
            : `Найдено: ${tnveds.length} записей`}
        </div>
        <div>
          <Loader show={loading} />
        </div>
      </div>
      <div className={cn(styles.data, { [styles.loading]: loading })}>
        <div className={cn(styles.shield, { [styles.active]: loading })} />
        {tnveds.map((item) => (
          <div
            key={item.code}
            className={styles.row}
            onClick={() => {
              onSelect(item.code);
            }}
          >
            <div>{item.code}</div>
            <div className={styles.name}>{item.description}</div>
            <div>{item.resultCNT}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

SearchTNVED.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default SearchTNVED;
