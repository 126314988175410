import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Loader.module.scss';

function Loader({ show }) {
  return (
    <>
      {show && (
        <div
          className={cn('spinner-border spinner-border-sm', styles.wrapper)}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </>
  );
}

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default Loader;
