import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './YesNoBlock.module.scss';

function YesNoBlock({ handleClick, value, title }) {
  const onClick = () => {
    handleClick(!value);
  };
  return (
    <div className={cn({ [styles.wrapper]: title })}>
      {title && <div className={styles.title}>{title}</div>}

      <div className={styles['buttons-wrapper']}>
        <button
          type="button"
          className={styles.button}
          disabled={value}
          onClick={onClick}
        >
          Да
        </button>
        <button
          type="button"
          className={styles.button}
          disabled={!value}
          onClick={onClick}
        >
          Нет
        </button>
      </div>
    </div>
  );
}

YesNoBlock.defaultProps = {
  title: '',
};

YesNoBlock.propTypes = {
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default YesNoBlock;
