import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import Calc from '../Calc/Calc';
import styles from './App.module.scss';
import api from '../../utils/api';
import CurrencyContext from '../../utils/currencyContext';

function App() {
  const [currency, setCurrency] = useState([]);

  useEffect(() => {
    api.getCurrency().then((res) => {
      if (res !== 'Error') {
        setCurrency(res);
      }
    });
  }, []);

  return (
    <CurrencyContext.Provider value={currency}>
      <div className={cn('container', styles.wrapper)}>
        <Calc />
      </div>
    </CurrencyContext.Provider>
  );
}

export default App;
