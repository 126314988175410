import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import Popup from 'reactjs-popup';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import 'reactjs-popup/dist/index.css';

import Modal from '../../Modal/Modal';
import SearchTNVED from '../SearchTNVED/SearchTNVED';
import SelectOption from './SelectOption/SelectOption';
import TnvedInput from './TnvedInput/TnvedInput';

import styles from './TnvedBlock.module.scss';
import api from '../../../utils/api';
import { tnvedInitial, packOptions } from '../../../utils/variables';
import CurrencyBlock from '../CurrencyBlock/CurrencyBlock';
import YesNoBlock from './YesNoBlock/YesNoBlock';

function TnvedBlock({
  tnved,
  setTnved,
  handleDelete,
  isCanDelete,
  contractType,
  deliveryType,
}) {
  const isDetail = Object.keys(tnved.detail).length > 0;
  const isFTL = deliveryType === 4;

  const [detailLoading, setDetailLoading] = useState(false); // Запрос детальной информации
  const [detail, setDetail] = useState({});
  useEffect(() => setTnved({ detail }), [detail]);

  let riskLabel = tnved.detail.risk;
  if (riskLabel && !+riskLabel.isNaN) {
    riskLabel = `Риск ${riskLabel} $`;
    const price = [2, 3].includes(contractType) ? tnved.riskPrice : tnved.price;
    if (price && +price < +tnved.detail.risk) {
      riskLabel = `СТОИМОСТЬ НИЖЕ РИСКА (${riskLabel})`;
    }
  }
  if (riskLabel && riskLabel.length > 40) {
    riskLabel = (
      <Popup
        trigger={() => <span>Риск (?)</span>}
        position={['bottom center', 'bottom right', 'bottom left']}
        closeOnDocumentClick
        on={['hover', 'focus']}
      >
        <span>{riskLabel}</span>
      </Popup>
    );
  }

  const fetchDetailTnvedInfoDebounce = useMemo(
    () =>
      debounce((code) => {
        setDetailLoading(true);
        api
          .getTNVEDDetail(code)
          .then((res) => {
            if (typeof res === 'object') {
              setDetail(res);
              // eslint-disable-next-line no-restricted-globals
              if (+res.risk || !isNaN(+res.risk)) {
                setTnved({ riskKg: res.risk });
              }
            }
          })
          .catch(() => setDetail({}))
          .finally(() => setDetailLoading(false));
      }, 800),
    []
  );
  const handleCode = (code) => {
    setTnved({ ...tnvedInitial, code });
    if (code.length === 10 && code !== tnved.detail.code)
      fetchDetailTnvedInfoDebounce(code);
  };

  const [volumeWeight, setVolumeWeight] = useState(''); // Запрос объёмного веса
  useEffect(() => {
    setTnved({ volumeWeight });
  }, [volumeWeight]);
  const fetchVolumeWeightDebounce = useMemo(
    () =>
      debounce((volume, weight) => {
        api
          .getVolumeWeigth(+volume, +weight)
          .then((res) => setVolumeWeight(String(res)))
          .catch(() => setVolumeWeight(''));
      }, 800),
    []
  );
  useEffect(() => {
    if (tnved.weight !== '' && tnved.volume !== '') {
      fetchVolumeWeightDebounce(tnved.volume, tnved.weight);
    }
  }, [tnved.weight, tnved.volume]);

  const deleveryInfoInputs = useMemo(
    () => [
      {
        label: (
          <>
            Стоимость
            <br /> по инвойсу, $
          </>
        ),
        key: 'price',
        disabled: false,
        required: contractType !== 3,
        show: contractType !== 3,
        onChange: (value) => {
          if (/^[0-9]*$/.test(value)) setTnved({ price: value });
        },
      },
      {
        label: (
          <>
            Риск для ТН ВЭД,
            <br />
            $/кг
          </>
        ),
        key: 'riskKg',
        disabled: false,
        required: true,
        show: [2, 3].includes(contractType),
        onChange: (value) => {
          const regValue = value.replace(/,/, '.');
          const isNull =
            /^[0-9]*\.{0,1}[0-9]*$/.test(regValue) && regValue !== '.';

          if (isNull) {
            setTnved({ riskKg: regValue });
          }
        },
      },
      {
        label: 'Вес, кг',
        key: 'weight',
        disabled: false,
        required: true,
        show: true,
        onChange: (value) => {
          setTnved({ volumeWeight: '' });
          setVolumeWeight('');
          if (/^[0-9]*$/.test(value)) setTnved({ weight: value });
        },
      },
      {
        label: 'Объем, м3',
        key: 'volume',
        disabled: false,
        required: true,
        show: true,
        onChange: (value) => {
          setTnved({ volumeWeight: '' });
          setVolumeWeight('');

          const regValue = value.replace(/,/, '.');
          const isNull =
            /^[0-9]*\.{0,1}[0-9]*$/.test(regValue) && regValue !== '.';

          if (isNull) {
            setTnved({ volume: regValue });
          }
        },
      },
      {
        label: 'Объемный вес, кг',
        key: 'volumeWeight',
        disabled: true,
        required: false,
        show: true,
        onChange: (value) => {
          if (/^[0-9]*$/.test(value)) setTnved({ weight: value });
        },
      },
      {
        label: 'Количество, шт.',
        key: 'count',
        disabled: false,
        required: false,
        show: !isFTL,
        onChange: (value) => {
          if (/^[0-9]*$/.test(value)) setTnved({ count: value });
        },
      },
      {
        label: (
          <>
            Количество мест,
            <br /> шт.
          </>
        ),
        key: 'countSeats',
        disabled: false,
        required: deliveryType === 4,
        show: isFTL,
        onChange: (value) => {
          if (/^[0-9]*$/.test(value)) setTnved({ countSeats: value });
        },
      },
      {
        label: (
          <>
            Количество штук
            <br /> в месте, шт.
          </>
        ),
        key: 'countPieceInSeats',
        disabled: false,
        required: deliveryType === 4,
        show: isFTL,
        onChange: (value) => {
          if (/^[0-9]*$/.test(value)) setTnved({ countPieceInSeats: value });
        },
      },
      {
        label: (
          <>
            Стоимость
            <br /> в риск, $
          </>
        ),
        key: 'riskPrice',
        disabled: true,
        required: false,
        show: [2, 3].includes(contractType),
      },
      {
        label:
          tnved.detail.additionalUnits && tnved.detail.additionalUnits[0]?.name,
        key: 'gedI1',
        disabled: false,
        required: false,
        show:
          tnved.detail.additionalUnits && tnved.detail.additionalUnits[0]?.name,
        onChange: (value) => {
          if (/^[0-9]*$/.test(value)) setTnved({ gedI1: value });
        },
      },
      {
        label:
          tnved.detail.additionalUnits && tnved.detail.additionalUnits[1]?.name,
        key: 'gedI2',
        disabled: false,
        required: false,
        show:
          tnved.detail.additionalUnits && tnved.detail.additionalUnits[1]?.name,
        onChange: (value) => {
          if (/^[0-9]*$/.test(value)) setTnved({ gedI2: value });
        },
      },
      {
        label:
          tnved.detail.typesExcise &&
          tnved.detail.typesExcise[tnved.idAkciz]?.additionalUnitsItem
            ?.prefName,
        key: 'gedIAkciz',
        disabled: false,
        required: false,
        show:
          tnved.detail.typesExcise &&
          tnved.detail.typesExcise[tnved.idAkciz]?.additionalUnitsItem
            ?.prefName,
        onChange: (value) => {
          if (/^[0-9]*$/.test(value)) setTnved({ gedIAkciz: value });
        },
      },
      {
        label:
          tnved.detail.typesImportDuty &&
          tnved.detail.typesImportDuty[tnved.idPoshlina]?.additionalUnitsItem
            ?.prefName,
        key: 'gedIPoshlina',
        disabled: false,
        required: false,
        show:
          tnved.detail.typesImportDuty &&
          tnved.detail.typesImportDuty[tnved.idPoshlina]?.additionalUnitsItem
            ?.prefName,
        onChange: (value) => {
          if (/^[0-9]*$/.test(value)) setTnved({ gedIPoshlina: value });
        },
      },
      {
        label:
          tnved.detail.typesVat &&
          tnved.detail.typesVat[tnved.idVat]?.additionalUnitsItem?.prefName,
        key: 'gedIVat',
        disabled: false,
        required: false,
        show:
          tnved.detail.typesVat &&
          tnved.detail.typesVat[tnved.idVat]?.additionalUnitsItem?.prefName,
        onChange: (value) => {
          if (/^[0-9]*$/.test(value)) setTnved({ gedIVat: value });
        },
      },
    ],
    [contractType, deliveryType, tnved.detail, tnved.idAkciz]
  );

  const inputsBlock = isDetail && (
    <>
      <div className={styles['inputs-wrapper']}>
        {isDetail &&
          deleveryInfoInputs.map((input) => {
            if (!input.show) return '';

            let value = tnved[input.key] || '';
            if (input.key === 'riskPrice') {
              value =
                tnved.riskKg && tnved.weight
                  ? (tnved.riskKg * tnved.weight).toFixed(2)
                  : '';
            }

            return (
              <TnvedInput
                key={input.key}
                value={value}
                handleChange={input.onChange}
                title={input.label}
                required={input.required}
                disabled={input.disabled}
              />
            );
          })}
      </div>

      {deliveryType === 4 && (
        <SelectOption
          onSelect={(value) => setTnved({ packageType: value })}
          options={packOptions}
          currentOptionIdx={tnved.packageType}
          title="Тип упаковки"
        />
      )}

      {isFTL && (
        <div className={styles['yes-no-block']}>
          <YesNoBlock
            value={tnved.isOversizePlaces}
            handleClick={(value) => setTnved({ isOversizePlaces: value })}
            title="Негабаритные места"
          />
          <YesNoBlock
            value={tnved.isPhotoReport}
            handleClick={(value) => setTnved({ isPhotoReport: value })}
            title="Фотоотчет / Пересчет мест"
          />
        </div>
      )}

      {tnved.detail.typesVat?.length > 0 && (
        <SelectOption
          onSelect={(value) => setTnved({ idVat: value })}
          options={tnved.detail.typesVat}
          currentOptionIdx={tnved.idVat}
          title="НДС"
        />
      )}

      {tnved.detail.typesImportDuty?.length > 0 && (
        <SelectOption
          onSelect={(value) => setTnved({ idPoshlina: value })}
          options={tnved.detail.typesImportDuty}
          currentOptionIdx={tnved.idPoshlina}
          title="Импортная пошлина"
        />
      )}

      {tnved.detail.typesExcise?.length > 0 && (
        <SelectOption
          onSelect={(value) => setTnved({ idAkciz: value })}
          options={tnved.detail.typesExcise}
          currentOptionIdx={tnved.idAkciz}
          title="Акциз"
        />
      )}

      {tnved.detail.antiDumpingDuty?.length > 0 && (
        <SelectOption
          onSelect={(value) => setTnved({ idAntiDumping: value })}
          options={tnved.detail.antiDumpingDuty}
          currentOptionIdx={tnved.idAntiDumping}
          title="Антидемпинговая пошлина"
        />
      )}

      {tnved.detail.countervailingDuty?.length > 0 && (
        <SelectOption
          onSelect={(value) => setTnved({ idCountervailingDuty: value })}
          options={tnved.detail.countervailingDuty}
          currentOptionIdx={tnved.idCountervailingDuty}
          title="Компенсационная пошлина"
        />
      )}

      {isFTL && (
        <textarea
          className={styles.description}
          placeholder="Введите описание товара в произвольной форме"
          value={tnved.description}
          onChange={(evt) => setTnved({ description: evt.target.value })}
        />
      )}
    </>
  );

  return (
    <>
      <div className={isDetail ? 'col-12 col-md-5 col-lg-4' : 'col-12'}>
        <div className={styles.block}>
          <div className={cn(styles.tnved, { [styles.min]: !isDetail })}>
            <div className="position-relative">
              <span className={styles.subtitle}>ТН ВЭД</span>
              <input
                type="text"
                className={cn(styles.input, {
                  [styles.error]:
                    tnved.code.length < 10 || (!isDetail && !detailLoading),
                })}
                value={tnved.code}
                onChange={(evt) => {
                  if (
                    /^[0-9]*$/.test(evt.target.value) &&
                    evt.target.value.length < 11
                  )
                    handleCode(evt.target.value);
                }}
              />
              {isCanDelete && (
                <div className={styles.delete} onClick={handleDelete}>
                  <span>&times;</span> Удалить
                </div>
              )}
            </div>
            <div>
              <Modal
                label="Подобрать"
                title="Подбор кода ТН ВЭД по наименованию. Примеры декларирования"
                buttonType="fill"
                content={(close) => (
                  <SearchTNVED
                    onSelect={(value) => {
                      handleCode(value);
                      close();
                    }}
                  />
                )}
              />
            </div>
          </div>

          {isDetail && (
            <>
              <CurrencyBlock />
              {tnved.detail.risk && (
                <div className={cn(styles['tnved-info'], styles.red)}>
                  <span className={styles.subtitle}>{riskLabel}</span>
                </div>
              )}
            </>
          )}

          <div className="d-block d-md-none mt-4">{inputsBlock}</div>
        </div>
      </div>
      {isDetail && (
        <div className="col-12 col-md-7 col-lg-8 d-none d-md-block">
          <div className={styles.block}>{inputsBlock}</div>
        </div>
      )}
    </>
  );
}

TnvedBlock.propTypes = {
  tnved: PropTypes.shape({
    code: PropTypes.string,
    price: PropTypes.string,
    riskPrice: PropTypes.string,
    riskKg: PropTypes.string,
    weight: PropTypes.string,
    volume: PropTypes.string,
    volumeWeight: PropTypes.string,
    count: PropTypes.string,
    isOversizePlaces: PropTypes.bool,
    isPhotoReport: PropTypes.bool,
    packageType: PropTypes.number,
    description: PropTypes.string,
    idPoshlina: PropTypes.number.isRequired,
    idAkciz: PropTypes.number.isRequired,
    idVat: PropTypes.number.isRequired,
    idAntiDumping: PropTypes.number.isRequired,
    idCountervailingDuty: PropTypes.number.isRequired,
    detail: PropTypes.shape({
      code: PropTypes.string,
      risk: PropTypes.string,
      priznak: PropTypes.string,
      additionalUnits: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string })
      ),
      typesVat: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      typesImportDuty: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      typesExcise: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      antiDumpingDuty: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      countervailingDuty: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  setTnved: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isCanDelete: PropTypes.bool.isRequired,
  contractType: PropTypes.number.isRequired,
  deliveryType: PropTypes.number.isRequired,
};

export default TnvedBlock;
