import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './TnvedInput.module.scss';

function TnvedInput({ value, handleChange, title, required, disabled }) {
  return (
    <div className={styles.column}>
      <div className={styles.title}>{title}</div>
      <input
        type="text"
        className={cn(styles.input, {
          [styles.error]: required && !value,
        })}
        disabled={disabled}
        value={value}
        onChange={(evt) => {
          handleChange(evt.target.value);
        }}
      />
    </div>
  );
}

TnvedInput.defaultProps = {
  handleChange: () => {},
  required: false,
  disabled: false,
};

TnvedInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleChange: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TnvedInput;
