import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Cross from '../Cross/Cross';

import styles from './Modal.module.scss';
import './Modal.css';

function Modal({ title, content, label, buttonType }) {
  return (
    <Popup
      trigger={
        <button className={cn(styles.button, styles[buttonType])} type="button">
          {label}
        </button>
      }
      modal
      nested
    >
      {(close) => (
        <div className={styles.modal}>
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <Cross color="#1E1C20" size={20} onClick={close} />
          </div>
          <div className={styles.content}>{content(close)}</div>
        </div>
      )}
    </Popup>
  );
}

export default Modal;

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  buttonType: PropTypes.oneOf(['fill', 'stroke']).isRequired,
};
