import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './CurrencyBlock.module.scss';
import CurrencyContext from '../../../utils/currencyContext';

import Loader from '../../Loader/Loader';

const renderCurrency = (currencyArray, currencyCode) => {
  const currency = currencyArray.find((el) => el.numCode === currencyCode);
  if (!currency) return '';
  let char = currency.charCode;
  if (currency.numCode === 840) char = '$';
  if (currency.numCode === 156) char = '¥';
  if (currency.numCode === 978) char = '€';

  return (
    <span>
      {char} {currency.value}
    </span>
  );
};

function CurrencyBlock({ type }) {
  return (
    <CurrencyContext.Consumer>
      {(currency) => (
        <div className={cn(styles.currency, styles[type])}>
          <span className={styles.subtitle}>
            Курс <span className="d-none d-xl-inline-block">валют</span>
          </span>
          {currency.length > 0 && (
            <>
              {renderCurrency(currency, 840)}
              {renderCurrency(currency, 978)}
              {renderCurrency(currency, 156)}
            </>
          )}
          <Loader show={currency.length === 0} />
        </div>
      )}
    </CurrencyContext.Consumer>
  );
}

CurrencyBlock.propTypes = {
  type: PropTypes.oneOf(['big', 'small']),
};
CurrencyBlock.defaultProps = {
  type: 'big',
};

export default CurrencyBlock;
