const isDev = false;

const api = {
  url: isDev
    ? 'https://prisma-info.ru'
    : document.querySelector('#root').dataset.url,

  defaultGetRequest(path) {
    return fetch(this.url + path, {
      method: 'GET',
    }).then(this.defaultHandleResponse);
  },

  defaultPostRequest(path, body) {
    return fetch(this.url + path, {
      method: 'POST',
      headers: {
        Accept: '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(this.defaultHandleResponse);
  },

  defaultHandleResponse(res) {
    if (res.status !== 200) {
      return 'Error';
    }

    const contentType = res.headers.get('Content-Type');
    if (!contentType) return null;
    if (contentType.startsWith('text/plain')) return res.text();
    if (contentType.startsWith('application/json')) return res.json();
    return null;
  },

  getTNVED(word) {
    return this.defaultGetRequest(
      `/api/externalApi/GetSelectionTnveds?search=${encodeURI(word)}`
    );
  },

  getTNVEDDetail(code) {
    return this.defaultGetRequest(
      `/api/externalApi/GetDetailInfoByTnvedCode?tnvedCode=${encodeURI(code)}`
    );
  },

  getVolumeWeigth(volume, weight) {
    return this.defaultGetRequest(
      `/api/externalApi/GetVolumetricWeight?volume=${volume}&weight=${weight}`
    );
  },

  сalculateTnvedDelivery(data) {
    return this.defaultPostRequest(`/api/externalApi/CalculateDelivery`, data);
  },

  сalculateRiskDelivery(data) {
    return this.defaultPostRequest(
      `/api/externalApi/CalculateRiskDelivery`,
      data
    );
  },

  getPDF(settlementId, departureCity, arrivalCity, border) {
    return this.defaultPostRequest(`/api/externalApi/GetPdfV2`, {
      settlementId,
      departureCity,
      arrivalCity,
      border,
    });
  },

  getResult(id, signal) {
    return fetch(`${this.url}/api/externalApi/GetResult/?id=${id}`, {
      method: 'GET',
      signal,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        return { status: 'Error' };
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          return { status: 'Cancel' };
        }
        return { status: 'Error' };
      });
  },

  getCitiesByWord(city) {
    return this.defaultGetRequest(
      `/api/externalApi/GetCitiesByWord?word=${encodeURI(
        city
      )}&limit=${25}&page=${1}`
    );
  },

  sendEmail(settlementId, recipient, arrivalCity, departureCity, border) {
    return this.defaultPostRequest(`/api/externalApi/SendPdfToMail`, {
      settlementId,
      departureCity,
      arrivalCity,
      recipient,
      border,
    });
  },

  sendToBitrix(
    settlementId,
    departureCity,
    arrivalCity,
    border,
    bitrixMessageTitle,
    additionalObjects,
    exciseDuty,
    additionalUnit
  ) {
    return this.defaultPostRequest(`/api/externalApi/SendSettlementToBitrix`, {
      settlementId,
      departureCity,
      arrivalCity,
      border,
      bitrixMessageTitle,
      exciseDuty,
      additionalUnit,
    });
  },

  getCurrency() {
    return this.defaultGetRequest(`/api/externalApi/GetCurrencyRates`);
  },

  getExternalRoute(city) {
    return this.defaultGetRequest(
      `/api/externalApi/GetExternalRouteByWord?word=${encodeURI(
        city
      )}&limit=25&page=1`
    );
  },

  getInputRoute(city) {
    return this.defaultGetRequest(
      `/api/externalApi/GetInputRouteByWord?word=${encodeURI(
        city
      )}&limit=25&page=1`
    );
  },

  getBorderRoute(province, inputCity) {
    return this.defaultGetRequest(
      `/api/externalApi/GetBorderByWord?province=${encodeURI(
        province
      )}&inputCity=${encodeURI(inputCity)}&limit=25&page=1`
    );
  },
};

export default api;
