import React from 'react';
import PropTypes from 'prop-types';

import styles from './Cross.module.scss';

const Cross = ({ color, size, onClick }) => (
  <button
    className={styles.close}
    type="button"
    style={{ width: `${size}px`, height: `${size}px` }}
    onClick={onClick}
  >
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 20 20">
      <path
        fill={color}
        d="M11.24,10l8.39-8.39c0.22-0.22,0.22-0.57,0-0.79l-0.45-0.45c-0.22-0.22-0.57-0.22-0.79,0L10,8.76L1.61,0.36
                  c-0.22-0.22-0.57-0.22-0.79,0L0.36,0.81c-0.22,0.22-0.22,0.57,0,0.79L8.76,10l-8.39,8.4c-0.22,0.22-0.22,0.57,0,0.79l0.45,0.45
                  c0.22,0.22,0.57,0.22,0.79,0L10,11.24l8.39,8.39c0.22,0.22,0.57,0.22,0.79,0l0.45-0.45c0.22-0.22,0.22-0.57,0-0.79L11.24,10z"
      />
    </svg>
  </button>
);

const arePropsEqual = (prevProps, nextProps) =>
  prevProps.color === nextProps.color && prevProps.size === nextProps.size;

export default React.memo(Cross, arePropsEqual);

Cross.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
