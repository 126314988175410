import React from 'react';
import cn from 'classnames';

import Logo from '../Logo/Logo';
import styles from './LogoBlock.module.scss';

function LogoBlock() {
  return (
    <div className={cn('col-12', styles.wrapper)}>
      <Logo />
      <div className={styles.label}>
        Модуль расчета стоимости доставки груза из Китая
      </div>
    </div>
  );
}

export default LogoBlock;
